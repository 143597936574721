import {useContext} from 'react';
import {useNavigate} from "react-router-dom";

import NewsFeedCard from '../../Component/NewsFeedCard';
import NewsCard from '../../Component/NewsCard';
import "./index.scss"

import {NewsContext} from '../../Context/NewsContext'

const MainPage = () => {
    let navigate = useNavigate();
    let {newsData, rankedData} = useContext(NewsContext);

    const navigateTONewsSection = (i) => {
        navigate("/" + i)
    }

    return (
        <div className='main-news-container'>
            <div className='main-title'>Tech News</div>
            {
            !newsData.length ? <div className='loading'>Please wait we are fetching the news....</div> :<ContentJSX rankedData = {rankedData}  newsData={newsData} navigateTONewsSection = {navigateTONewsSection}></ContentJSX>
        }
            
            
        </div>

    )
}
function ContentJSX({rankedData, newsData, navigateTONewsSection}) {
    return <><ReccomendedNewsLoadedTitle></ReccomendedNewsLoadedTitle><ReccomendedNews rankedData={rankedData}></ReccomendedNews> <NewsLoadedTitle></NewsLoadedTitle><LandingpageNewsCards newsData={newsData} navigateTONewsSection={navigateTONewsSection}></LandingpageNewsCards></>;
}
function ReccomendedNewsLoadedTitle() {
    return <div className='sub-title'>Today's Reccomendations
        <span>(Top 10 reccomendations for more please scroll down)</span>
    </div>;
}
function ReccomendedNews({rankedData}){
    return rankedData.map((key,index)=>{
        return <NewsCard data={key}></NewsCard>
    })
} 

function LandingpageNewsCards({newsData, navigateTONewsSection}) {
    return newsData.length && newsData.map((key, index) => <NewsFeedCard key={
            (key.author ?. name || key.author)
        }
        author={
            (key.author ?. name || key.author)
        }
        image={
            key.image
        }
        onClick={
            function () {
                navigateTONewsSection(index);
            }
    }></NewsFeedCard>);
}

function NewsLoadedTitle() {
    return <div className='sub-title'>Top news sites
        <span>(please click on any site to see its todays news)</span>
    </div>;
}


export default MainPage;
