

const getTodayNews = function(){
    return fetch("https://technewsapi.dravid.dev/", {
        method: "GET",
        headers: {
            "api_key":"364F08AB177AF408"
        }
}
        ).then(response=>response.json());
}


export {getTodayNews};