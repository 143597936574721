import {createContext,useEffect,useState} from 'react';

import {getTodayNews} from '../../Util/getNews';
const NewsContext = createContext();
const DEFAULT_DATA = {newsData:[]};

function NewsContextProvider(props) {

    const existingData = sessionStorage['newsData']||JSON.stringify(DEFAULT_DATA);
    const[data,setData] = useState(JSON.parse(existingData).newsData);
    const[rankedData,setRankedData] = useState(JSON.parse(existingData).rankingData);

    useEffect(() => {
      console.log('refreshing')
        getTodayNews().then(
            response=>{
              setData(response.newsData);
              setRankedData(response.rankingData);
              sessionStorage['newsData'] = JSON.stringify(response);
            }
        )
    }, [])  
    return (
      <NewsContext.Provider value={{newsData:data,rankedData:rankedData}}>
        {props.children}
      </NewsContext.Provider>
    );
  }

export {NewsContextProvider,NewsContext};