import "./index.scss"


const NewsFeedCard = (props)=>{
    const newsList = props.news;
    const imageUrl = props.image;
    const author = props.author;
    return(
        <div className="news-card-container" onClick={props.onClick}>
            <div className="news-card" style={{ backgroundImage: `url(${imageUrl})` }}>
                <div className="news-text"><span dangerouslySetInnerHTML={{ __html: author }}></span></div>
            </div>
            <div className="mobile-news-text"><span dangerouslySetInnerHTML={{ __html: author }}></span></div>
        </div>
    )
}

export default NewsFeedCard;