import {
  HashRouter as Router,
  Route,
  Routes, 
} from 'react-router-dom';
import {NewsContextProvider} from './Context/NewsContext';

import MainPage from './Page/MainPage'
import NewsListPage from './Page/NewsListPage'

function App() {
  return (
    <NewsContextProvider>
    <Router>
      <Routes>
        <Route path="/" exact={true} element={<MainPage />} />
        <Route path="/:newsid" exact={true} element={<NewsListPage />} />
      </Routes>
    </Router>
    </NewsContextProvider>
  );
}

export default App;
