import { useParams } from "react-router-dom";
import {useContext} from 'react';
import {NewsContext} from '../../Context/NewsContext'
import NewsCard from "../../Component/NewsCard";


export default function NewsListPage() {
    let params = useParams();
    let {newsData} = useContext(NewsContext);

    const {author,news} = newsData.length>=params.newsid ? newsData[params.newsid] : {author:"",news:[]};
    
    return (<div className="card-container">
              <div className="news-title">{author?.name||author}</div>
                {news.map(x=><NewsCard data={x}></NewsCard>)}
            </div>);
  }